import Vue from 'vue'
import App from './App.vue'
import './main.css'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.config.productionTip = false
// import VConsole from 'vconsole';
// new VConsole({ theme: 'dark' });

Vue.use(Vant);



new Vue({
  render: h => h(App),
}).$mount('#app')

