<template>
  <van-overlay :show="true" class-name="overlay-box">
    <div class="nav-bar-box">
      <van-icon name="arrow-left" @click="goback" /><span class="nav-bar-title">{{ title }}</span>
    </div>
    <div class="qrcode-box">
      <div class="qrcode-accident" v-if="accident" @click="reQrScanner">
        <van-icon name="replay" />
      </div>
      <video class="qrcode-video" ref="qr-video" height="100%"></video>
    </div>
    <van-image class="icon-box" width="2.95rem" height="2.95rem" :src="dragonIcon" />
  </van-overlay>
</template>
<script>
import QrScanner from 'qr-scanner';
import dragonIcon from './dragon.png';
export default {
  name: 'qrcode-modal',
  props: {},
  data() {
    return {
      dragonIcon,
      title: '扫描卷子中的二维码，即可获取题目讲解~',
      qrScanner: null,
      accident: false,
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async generateQRCode() {
      try {
        const qrVideo = this.$refs['qr-video'];
        this.qrScanner = new QrScanner(qrVideo, (result) => {
          console.log('扫描结果：', result);
          // 处理扫描结果
          this.qrScanner.stop();
          this.accident = true;
        });
        this.qrScanner.start();
      } catch {
        this.accident = true;
      }
    },
    reQrScanner() {
      this.accident = false;
      this.qrScanner.stop();
      this.qrScanner.destroy();
      this.qrScanner = null;
      this.generateQRCode();
    },
  },
  mounted() {
    this.generateQRCode();
  },
  beforeDestroy() {
    this.qrScanner.destroy();
    this.qrScanner = null;
  },
};
</script>

<style scoped>
.overlay-box {
  display: flex;
  height: 100vh;
  width: 100vw;
}
.nav-bar-box {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  font-size: 0.23rem;
  padding: 0.23rem 0.38rem;
  display: flex;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
}
.nav-bar-title {
  flex: 1 1 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qrcode-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  background-color: white;
  border-radius: 0.47rem;
  margin: auto;
  overflow: hidden;
}
.qrcode-accident {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #ffc72f;
  z-index: 666;
  background-color: white;
  width: 500px;
  height: 500px;
  border-radius: 0.47rem;
}
.icon-box {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>