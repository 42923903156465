<template>
  <div id="app">
    <qrcode-modal />
  </div>
</template>

<script>
import QrcodeModal from './components/qrcode-modal.vue';
export default {
  components: {
    'qrcode-modal': QrcodeModal,
  },
  data() {
    return {};
  },
};
</script>

<style>
#app {
  text-align: center;
}
</style>
